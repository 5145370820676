import React from "react";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <NavBar />

      {/* breadcrumb */}
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>Terms and Conditions</h1>
                <ul className="page-breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Terms and Conditions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}

      <div className="page-wrapper section-space--inner--top--120">
        {/*About section start*/}
        <div className="about-section section-space--inner--bottom--120">
          <div className="container">
            <div className="row row-25 align-items-center">
              <div className="col-lg-12 col-12 mb-30">
                <div className="about-content-two">
                  <h3>Introduction</h3>
                  <p>
                    Welcome to LiveEasy. By accessing or using our website and services, 
                    you agree to be bound by these Terms and Conditions. If you do not agree, 
                    please refrain from using our website.
                  </p>

                  <h3>Definitions</h3>
                  <ul>
                    <li>
                      "LiveEasy" refers to the website and services provided at https://liveeasyweb.com
                    </li>
                    <li>
                      "User" refers to any individual accessing or using the website.
                    </li>
                  </ul>

                  <h3>Use of Website</h3>
                  <ul>
                    <li>
                      You must be at least 18 years old or have parental/guardian consent to use this website.
                    </li>
                    <li>
                      You agree to use the website for lawful purposes only.
                    </li>
                  </ul>

                  <h3>Account Registration</h3>
                  <ul>
                    <li>
                      Users may be required to register an account to access certain features.
                    </li>
                    <li>
                      You are responsible for keeping your account information confidential.
                    </li>
                  </ul>

                  <h3>Prohibited Activities</h3>
                  <ul>
                    <li>
                      Unauthorized use of the website or content is prohibited.
                    </li>
                    <li>
                      Activities such as hacking, data mining, or spreading harmful content are strictly forbidden.
                    </li>
                  </ul>

                  <h3>Intellectual Property</h3>
                  <p>
                    All content on LiveEasy is owned or licensed by us. You may not reproduce, 
                    distribute, or create derivative works without written consent.
                  </p>

                  <h3>Third-Party Links</h3>
                  <p>
                    Our website may contain links to third-party websites. We are not responsible 
                    for the content or practices of these websites.
                  </p>

                  <h3>Limitation of Liability</h3>
                  <p>
                    LiveEasy is not liable for damages resulting from your use of the website or services.
                  </p>

                  <h3>Modifications</h3>
                  <p>
                    We reserve the right to modify these Terms and Conditions at any time. 
                    Please review them periodically for updates.
                  </p>

                  <h3>Contact Us:</h3>
                  <p className="mb-2">
                    If you have any questions or concerns about our Terms and Conditions, 
                    please contact us at:
                  </p>
                  <p>Email: support@jjaogroup.com</p>
                  <p>Email: niyi@jjaogroup.com</p>
                  <p>Phone: +234 803 065 4110</p>
                  <p>Address: 15, Moshood Balogun St, Jumofak igbo oluwo, Ikorodu, Lagos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*About section end*/}
      </div>
      <Footer/>
    </div>
  );
};

export default TermsAndConditions;