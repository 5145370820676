import React from "react";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <NavBar />

      {/* breadcrumb */}
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>Privacy Policy</h1>
                <ul className="page-breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Privacy Policy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}

      <div className="page-wrapper section-space--inner--top--120">
        {/*About section start*/}
        <div className="about-section section-space--inner--bottom--120">
          <div className="container">
            <div className="row row-25 align-items-center">
              <div className="col-lg-12 col-12 mb-30">
                <div className="about-content-two">
                  <p>
                    {" "}
                    At Liveeasy, we are committed to protecting your privacy and
                    ensuring the security of your personal information. This
                    Privacy Policy outlines how we collect, use, disclose, and
                    protect the information you provide when using our website
                    and services. Please read this Privacy Policy carefully to
                    understand our practices regarding your personal data.
                  </p>

                  <h3>Information We Collect:</h3>
                  <ol type="a">
                    <li> Personal Information:</li>
                    <p className="mt-2">
                      When you subscribe to our services, we may collect
                      personal information such as your name, email address,
                      phone number, and billing details.
                    </p>
                    <li>Usage Data</li>
                    <p className="mt-2">
                      We may collect non-personal information about your
                      interaction with our website, including your IP address,
                      browser type, device information, and pages visited. This
                      information is collected through cookies and similar
                      technologies.
                    </p>
                  </ol>

                  <h3>How We Use Your Information:</h3>
                  <ol type="a">
                    <li>Provision Of Services:</li>
                    <p className="mt-2">
                      We use your personal information to provide you with our
                      software property and HOA Community Management services,
                      including user authentication, customer support, and
                      communication.
                    </p>
                    <li>Improve User Experience</li>
                    <p className="mt-2">
                      We may analyze usage data to enhance our website's
                      functionality, tailor our services to better meet your
                      needs, and improve user experience.
                    </p>
                    <li>Marketing and Communication</li>
                    <p className="mt-2">
                      With your consent, we may send you promotional emails,
                      newsletters, or updates about our products and services.
                      You can opt out of these communications at any time.
                    </p>
                  </ol>

                  <h3>Cookies</h3>
                  <p>
                    We use cookies to enhance your experience. You can adjust your browser settings to refuse cookies; 
                    however, some features of the website may not function properly.
                  </p>

                  <h3>Data Sharing And Disclosure:</h3>
                  <ol type="a">
                    <li>Third Party Service Providers:</li>
                    <p className="mt-2">
                      We may engage trusted third-party service providers to
                      assist us in delivering our services. These providers have
                      access to your personal information solely for the purpose
                      of performing their assigned tasks and are obligated to
                      maintain its confidentiality.
                    </p>
                    <li>Legal Requirements</li>
                    <p className="mt-2">
                      We may disclose your personal information if required to
                      do so by law or if we believe in good faith that such
                      disclosure is necessary to comply with legal obligations,
                      protect our rights, or investigate potential violations.
                    </p>
                    <li>Cross-Border Data Transfer</li>
                    <p className="mt-2">
                      Your personal information may be transferred to, stored,
                      and processed in Nigeria, the United States, or any other
                      country where Liveeasy or its service providers maintain
                      facilities. By using our services, you consent to the
                      transfer of your information across borders.
                    </p>
                  </ol>

                  <h3>Data Security:</h3>
                  <ol type="a">
                    <li>
                      We employ industry-standard security measures to protect
                      your personal information from unauthorized access,
                      disclosure, alteration, or destruction.
                    </li>
                    <li className="mt-2">
                      While we strive to ensure the security of your
                      information, please note that no method of transmission
                      over the internet or electronic storage is entirely
                      secure. We cannot guarantee absolute security.
                    </li>
                  </ol>

                  <h3>Your Privacy Choices:</h3>
                  <ol type="a">
                    <li>Access, Correction And Deletion:</li>
                    <p className="mt-2">
                      You have the right to access, correct, or delete your
                      personal information. If you wish to exercise these
                      rights, please contact us using the information provided
                      below.
                    </p>
                    <li>Marketing Communications</li>
                    <p className="mt-2">
                      You can unsubscribe from our marketing communications by
                      following the instructions in the email or by contacting
                      us.
                    </p>
                  </ol>

                  <h3>Changes To The Privacy Policy:</h3>
                  <p>
                    We may update this Privacy Policy periodically to reflect
                    changes in our practices or legal requirements. We will
                    notify you of any material changes by posting the revised
                    Privacy Policy on our website.
                  </p>

                  <h3>Contact Us:</h3>
                  <p className="mb-2">
                    If you have any questions or concerns about our Privacy
                    Policy or the handling of your personal information, please
                    contact us at:
                  </p>
                  <p>Email: support@jjaogroup.com</p>
                  <p>Email: niyi@jjaogroup.com</p>
                  <p>Phone: +234 803 065 4110</p>
                  <p>Address: 15, Moshood Balogun St, Jumofak igbo oluwo, Ikorodu, Lagos</p>


                  <p className="mt-5">By using our website and services, you acknowledge that you have read and understood this <strong>Privacy Policy</strong> and agree to the collection, use, and disclosure of your information as described herein.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*About section end*/}
      </div>
      <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
